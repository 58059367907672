const SubmissionForm = () =>
{
    const submissionFormButton = document.querySelector('.submission-block-form__button')
    const submissionFormFile = document.querySelector('.js-file-upload')
    const submissionFormMessage = document.querySelector('.submission-block-form__thankyou')

    if (submissionFormMessage) {
        submissionFormMessage.scrollIntoView({ behavior: 'smooth' })
    }

    const getInputName = (e) => {
        const fileName = e.target.value.split('\\').pop()
        if (fileName) {
            document.querySelector('.file__name').innerHTML = fileName
            document.querySelector('.file').classList.add('uploaded')
        }
    }

    if (submissionFormFile) {
        submissionFormFile.addEventListener('change', (e) => { getInputName(e) })
    }
}

export default SubmissionForm;

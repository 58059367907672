import { wait, getClosest } from 'js/utils'
import VideoPlayer from '../utils/video-player'

let videoActiveState = false
let articlePlayerParent
let player
let videoHost
let videoId
let videoUrl
let buttonHolder


function playVideo() {
  buttonHolder.classList.add('ArticleVideoPlayButton__holder--active')
  player.open()
  videoActiveState = true
}

function stopVideo() {
  buttonHolder.classList.remove('ArticleVideoPlayButton__holder--active')
  player.close()
  videoActiveState = false
}
function toggleVideo() {
  if (!videoActiveState) {
    playVideo()
  } else {
    stopVideo()
  }
}

function handleKeyboardInput() {
  // Close video-overlay on esc-keypress
  window.addEventListener('keydown', (e) => {
    if (videoActiveState === true && (e.keyCode === 27 || e.key === 'Escape')) {
      if (!player) {
        return false
      }
      return stopVideo()
    }
  })
}


function articleVideoHandler() {
  const playButton = document.querySelector('.ArticleVideoPlayButton')

  if (!playButton || typeof playButton === 'undefined') {
    return false
  }

  articlePlayerParent = getClosest(playButton, '.SingleArticleHeader')
  if (!articlePlayerParent) {
    articlePlayerParent = document.body
  }
  videoHost = playButton.dataset.host
  videoId = playButton.dataset.id
  videoUrl = playButton.dataset.url
  buttonHolder = playButton.querySelector('.ArticleVideoPlayButton__holder')

  if (!player) {
    player = new VideoPlayer(videoHost, videoId, videoUrl, articlePlayerParent)
  }
  wait(1500)()
    .then(() => buttonHolder.classList.remove('ArticleVideoPlayButton__holder--hidden'))

  // Close video on esc-press
  handleKeyboardInput()
  return playButton.addEventListener('click', () => toggleVideo(), false)
}


export default articleVideoHandler

const categoryDescriptionHandler = () => {
    const description = document.querySelector('.CategoryDescription')
    const descriptionBg = document.querySelector('.CategoryDescription__green-bg')
    const descriptionButton = document.querySelector('.CategoryDescription__button')

    if (description != null) {
        const descriptionHeight = description.clientHeight;

        if (descriptionHeight > 188) {
            description.classList.add('fold')
            descriptionButton.style.display = 'block'
            descriptionBg.style.display = 'block'
        } else {
            description.classList.remove('fold')
            descriptionButton.style.display = 'none'
            descriptionBg.style.display = 'none'
        }

        descriptionButton.addEventListener('click', (e) => {
            description.classList.toggle('fold')
            if (description.classList.contains('fold')) {
                e.currentTarget.innerHTML = 'Läs mer'
            } else {
                e.currentTarget.innerHTML = 'Stäng'
            }
        });
    }
}

export default categoryDescriptionHandler;

import 'intersection-observer'
import './lib/picturefill'
import './lib/ie-fixes'
import './lib/polyfills'
import loadImgLazyloadScript from './modules/lazyload-images'
import siteNavigationHandler from './modules/site-navigation'
import searchHandler from './modules/search-handler'
import handleCookieDialog from './modules/cookie-dialog'
import sliderHandler from './modules/sliders'
import newsletterSignup from './modules/newsletter-signup'
import embedVideoPlayer from './modules/embed-video-player'
import articleVideoHandler from './modules/article-video-handler'
import loadPlaceholderImages from './modules/image-placeholders'
import sneakpeakFormHandler from './modules/sneakpeek-handler'
import categoryDescriptionHandler from './modules/category-desc'
import eventTracking from './modules/event-tracking'
import youtubeHandler from './modules/youtubeHandler'
import SubmissionForm from './modules/SubmissionForm'

function loadJS() {
  (document.querySelectorAll('a')).forEach(link => {
    link.hostname !== location.hostname && link.setAttribute('target', '_blank');
  })

  document.createElement('picture')
  youtubeHandler()
  eventTracking()
  loadPlaceholderImages()
  siteNavigationHandler()
  searchHandler()
  handleCookieDialog()
  sliderHandler()
  newsletterSignup()
  embedVideoPlayer()
  articleVideoHandler()
  sneakpeakFormHandler()
  categoryDescriptionHandler()
  SubmissionForm()
}
loadImgLazyloadScript(window, document)

document.addEventListener('DOMContentLoaded', loadJS())

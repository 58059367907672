const youtubeHandler = () =>
{
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Init empty array of iframe IDs, one from each video
    let iframeIds = [];

    // For each iframe you find, add its ID to the iframeIds array
    const iframes = document.querySelectorAll(".video-item iframe");
    iframes.forEach(function(iframe) {
    	iframeIds.push(iframe.id);
    });

    // Once the YouTube API is ready, for each iframeId in your array, create
    // a new YT player and give it the onReady event
    function onYouTubeIframeAPIReady() {
    	iframeIds.forEach(function(iframeId) {
    		const player = new YT.Player(iframeId, {
    			events: {
    				onReady: onPlayerReady
    			}
    		});
    	});
    }

    // Init empty array of iframe YT objects for use elsewhere
    // Here I only use this to iterate through and pause all videos when
    // another begins playing
    let iframeObjects = [];

    // Shared onReady event which adds events to each video's corresponding
    // play and stop buttons
    function onPlayerReady(event) {
    	const iframeObject = event.target;
    	const iframeElement = iframeObject.a;
    	const videoContainer = iframeElement.parentElement;
    	const play = videoContainer.querySelector(".play");
    	const stop = videoContainer.querySelector(".stop");

    	// Push current iframe object to array
    	iframeObjects.push(iframeObject);

    	play.addEventListener("click", function() {
    		// Pause all videos currently playing
    		iframeObjects.forEach(function(scopediframeObject) {
    			scopediframeObject.pauseVideo();
    			const scopediframeElement = scopediframeObject.a;
    			scopediframeElement.classList.remove('isPlaying');
    		});

    		// Play selected video
    		iframeObject.playVideo();
    		iframeElement.classList.add('isPlaying');
    	});

    	stop.addEventListener("click", function() {
    		iframeObject.pauseVideo();
    		iframeElement.classList.remove('isPlaying');
    	});
    }

}

export default youtubeHandler;

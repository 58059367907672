import PlayIcon from 'assets/images/play-icon.svg'

const videoPlayers = Array.from(document.querySelectorAll('.VideoPlayer'))

const getPlaceholderImageUrl = async (id, host, url) => {
  let imgSrc
  if (host === 'youtube') {
    imgSrc = `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
  } else if (host === 'vimeo') {
    const vimeoResponse = await fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
    const json = await vimeoResponse.json()
    // eslint-disable-next-line
    const { thumbnail_url } = json
    // eslint-disable-next-line
    imgSrc = thumbnail_url
  }

  return imgSrc
}

const createPlayIcon = () => {
  const playBtnHolder = document.createElement('div')
  const playIconWrap = document.createElement('div')
  const playBtn = document.createElement('img')
  playBtn.src = PlayIcon
  playBtn.setAttribute('src', PlayIcon)
  playBtnHolder.classList.add(
    'VideoPlayer__play-button',
    'w-24',
    'text-center',
    'absolute',
    'pin-center'
  )
  playIconWrap.classList.add('scaling-svg-container')
  playBtn.classList.add(
    'VideoPlayer__play-icon',
    'block',
    'w-full',
    'h-auto',
    'cursor-pointer',
    'scaling-svg'
  )

  playIconWrap.appendChild(playBtn)
  playBtnHolder.appendChild(playIconWrap)
  return playBtnHolder
}

function createIframe(url, host, placeholderElement) {
  const iframe = document.createElement('iframe')
  const autoplayParam = host === 'youtube' ? '?autoplay=1' : '&autoplay=1'
  const embedSource = `${url}${autoplayParam}`
  iframe.classList.add('VideoPlayer__iframe')
  iframe.setAttribute('src', embedSource)
  iframe.setAttribute('frameborder', '0')
  iframe.setAttribute('allowfullscreen', '1')
  placeholderElement.parentNode.replaceChild(iframe, placeholderElement)
}

async function handleVideoPlayer(player) {
  const videoId = player.dataset.id
  const videoUrl = player.dataset.url
  const videoHost = player.dataset.host
  const placeholder = player.querySelector('.VideoPlayer__placeholder')
  const playButton = createPlayIcon()

  // add placeholderImage
  const placeholderImage = await getPlaceholderImageUrl(videoId, videoHost, videoUrl)
  placeholder.style.backgroundImage = `url('${placeholderImage}')`


  // add play button
  placeholder.appendChild(playButton)

  placeholder.addEventListener('click', () => createIframe(videoUrl, videoHost, placeholder), false)
}

function embedVideoPlayer() {
  if (
    !videoPlayers
    || videoPlayers.length < 1
    || typeof videoPlayers === 'undefined'
  ) {
    return false
  }
  return videoPlayers.map(player => handleVideoPlayer(player))
}

export default embedVideoPlayer

import { wait } from 'js/utils'
import {
  docCookies,
  maxAgeToGMT,
} from '../lib/cookiehelper'

const cookieName = 'sneakpeekApproved'

const ajaxurl = window.AJAX_URL

const messageElement = (message, statusCode) => {
  const messageElem = document.createElement('span')
  const messageClass = 'Sneakpeak__response-message'
  messageElem.id = `mailchimpResponseMessage__${parseInt((Math.random() * 1000), 10)}`
  messageElem.classList.add(messageClass, 'mt-4', 'block')

  if (statusCode === 200) {
    messageElem.classList.add(`${messageClass}--success`, 'text-purple-dark')
  } else {
    messageElem.classList.add(`${messageClass}--fail`, 'text-pink-dark')
  }

  messageElem.innerHTML = message
  return messageElem
}
function removeOverlay(transitionOrNot) {
  const transition = transitionOrNot
  const overlay = document.getElementById('SneakpeekOverlay')
  document.body.classList.remove('sneakpeek-overlay-open')
  if (window.scrollY) {
    window.scroll(0, 0) // reset the scroll position to the top left of the document.
  }

  if (!transition) {
    overlay.remove()
  } else {
    wait(800)().then(() => {
      overlay.classList.add('Sneakpeek__overlay--hidden')
    }).then(() => wait(800)().then(() => {
      overlay.remove()
    }))
  }
  if (!docCookies.hasItem(cookieName)) {
    docCookies.setItem(cookieName, 1, maxAgeToGMT(31536e3))
  }
}

const doSubscription = (emailAddress, formElement, articleId) => {
  const responseMessageHolder = formElement.querySelector('.Sneakpeek__response-message-holder')

  responseMessageHolder.innerHTML = ''
  const submitButton = formElement.querySelector('button.Sneakpeek__submit-button')
  const request = new XMLHttpRequest()
  const formData = new FormData()

  formData.append('action', 'RegisterSneakpeekUser')
  formData.append('email', emailAddress)
  formData.append('article_id', articleId)

  request.onreadystatechange = () => {
    if (request.readyState === 1) {
      submitButton.setAttribute('disabled', 'disabled')
    }

    if (request.readyState === 4) {
      submitButton.removeAttribute('disabled')

      if (request.status === 200) {
        const res = JSON.parse(request.responseText)
        fbq('trackCustom', 'Unlocked sneakpeek article');

        // reset form if success
        if (res.statusCode === 200) {
          formElement.reset()
          removeOverlay(true)
        }
        if (res.statusCode === 404) {
          const msg = messageElement(res.message, res.statusCode)
          responseMessageHolder.appendChild(msg)
        }
      } else {
        // eslint-disable-next-line
        console.log(`ERROR: ${request.status} ${request.statusText}`)
      }
    }
  }

  request.open('POST', ajaxurl, true)
  request.send(formData)
}

const submitForm = (e, formElement, inputElement, articleId) => {
  e.preventDefault()
  if (inputElement.value) {
    return doSubscription(inputElement.value, formElement, articleId)
  }
  return false
}

const inputHandler = (inputElement, formElement) => {
  if (inputElement.value.length >= 1) {
    formElement.classList.add('has-value')
    inputElement.classList.add('has-value')
  } else {
    formElement.classList.remove('has-value')
    inputElement.classList.remove('has-value')
  }
}
// eslint-disable-next-line
function sneakpeakFormHandler() {

  const SneakpeakOverlay = document.getElementById('SneakpeekOverlay')
  if (!SneakpeakOverlay || typeof SneakpeakOverlay === 'undefined') {
    return false
  }
  document.body.classList.add('sneakpeek-overlay-open')


  const sneakpeekForm = SneakpeakOverlay.querySelector('form.Sneakpeek__form')
  if (!sneakpeekForm || typeof sneakpeekForm === 'undefined') {
    return false
  }

  if (!docCookies.hasItem(cookieName)) {
    sneakpeekForm.classList.remove('hidden')
    sneakpeekForm.classList.add('block')
  }
  if (docCookies.hasItem(cookieName)) return removeOverlay(true)

  const inputElement = sneakpeekForm.querySelector('input.Sneakpeak__input')
  if (!inputElement) {
    return false
  }

  inputElement.onkeyup = () => inputHandler(inputElement, sneakpeekForm)

  if (sneakpeekForm.addEventListener) {
    sneakpeekForm.addEventListener('submit', e => submitForm(e, sneakpeekForm, inputElement, ARTICLE_ID_SNEAKPEEK), false)
  } else if (sneakpeekForm.attachEvent) {
    sneakpeekForm.attachEvent('onsubmit', e => submitForm(e, sneakpeekForm, inputElement, ARTICLE_ID_SNEAKPEEK))
  }
}
export default sneakpeakFormHandler

/* eslint-disable */

function loadImgLazyloadScript(w, d) {

  const b = d.getElementsByTagName('body')[0]
  const s = d.createElement('script')
  // const v = !('IntersectionObserver' in w) ? '8.17.0' : '10.19.0'
  const v = '8.17.0'
  s.async = true // This includes the script as async. See the "recipes" section for more information about async loading of LazyLoad.
  s.src = `https://cdn.jsdelivr.net/npm/vanilla-lazyload@${v}/dist/lazyload.min.js`

  w.lazyLoadOptions = []
  const defautlOptions = {
    elements_selector: '.lazy-image',
    callback_load(el) {
      // eslint-disable-next-line
    }
  }

  const scrollableContainerOptions = {
    elements_selector: '.scrollable-content .lazy-image',
    container: document.querySelector('.scrollable-content'),
    callback_load(el) {
    // eslint-disable-next-line
    }
  }
  // eslint-disable-next-line
  w.lazyLoadOptions.push(defautlOptions)
  if (document.querySelector('.scrollable-content') && typeof document.querySelector('.scrollable-content') !== 'undefined') {
    w.lazyLoadOptions.push(scrollableContainerOptions)
  }
  b.appendChild(s)
}
export default loadImgLazyloadScript

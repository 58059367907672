import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'
require('flickity-imagesloaded');


function bookTipsSliders() {
  const sliders = Array.from(document.querySelectorAll('.BookTipsSlider__js-slider'))
  if (!sliders || sliders.length < 1 || typeof sliders === 'undefined') {
    return false
  }


  // eslint-disable-next-line
    const jsSliders = sliders.map(slider => {

        const flkty = new Flickity(slider, {
          cellSelector: '.BookTipsSlider__slide-item',
          wrapAround: false,
          prevNextButtons: true,
          pageDots: false,
          cellAlign: 'left',
          contain: true,
          setGallerySize: true,
          imagesLoaded: true
        })



        return flkty
    });

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    return jsSliders;

}
// eslint-disable-next-line
function sliderHandler() {
  bookTipsSliders()
}
export default sliderHandler

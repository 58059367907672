import { wait } from 'js/utils'

const menuButton = document.getElementById('SiteNavigationMenuButton')
const searchButtons = Array.from(document.querySelectorAll('.SiteNavigationSearchButton'))
const SiteNavigationMenu = document.getElementById('SiteNavigationMenu')
const SiteNavigation = document.getElementById('SiteNavigation')
const navHolder = document.getElementById('SiteNavigationMenuHolder')
const searchHolder = document.getElementById('SiteNavigationMenuSearch')


// const SearchButton = document.getElementById('SiteNavigationSearchButton')
const bodyMenuOpenClass = 'navigation-menu-open'
const siteNavigationIsClosingClass = 'SiteNavigation--is-closing'
const siteNavigationMenuOpenClass = 'SiteNavigationMenu--open'
const menuButtonOpenClass = 'is-active'
let menuOpenState = false
let searchOpenState = false
let navigationOpenState = false

function closeNavigation() {
  navigationOpenState = false
  navHolder.classList.remove('block')
  navHolder.classList.add('hidden')
}

function openSearch() {
  searchOpenState = true
  // eslint-disable-next-line
  // const SearchFormInput = document.getElementById('SearchFormInput')
  wait(200)().then(() => document.getElementById('SearchFormInput').focus())
  searchHolder.classList.add('block')
  searchHolder.classList.remove('hidden')
  closeNavigation()
}
function closeSearch() {
  searchOpenState = false
  searchHolder.classList.remove('block')
  searchHolder.classList.add('hidden')
}
function openNavigation() {
  navHolder.classList.add('block')
  navHolder.classList.remove('hidden')
  navigationOpenState = true
}

function openMenu() {
  menuOpenState = true
  menuButton.classList.add(menuButtonOpenClass)
  SiteNavigationMenu.classList.add(siteNavigationMenuOpenClass)
  document.body.classList.add(bodyMenuOpenClass)
}

function closeMenu() {
  menuOpenState = false
  menuButton.classList.remove(menuButtonOpenClass)
  SiteNavigationMenu.classList.remove(siteNavigationMenuOpenClass)
  document.body.classList.remove(bodyMenuOpenClass)
  SiteNavigation.classList.add(siteNavigationIsClosingClass)
  closeSearch()
  closeNavigation()
  wait(2000)().then(() => SiteNavigation.classList.remove(siteNavigationIsClosingClass))
}


function handleSearchToggle() {
  if (!searchButtons || typeof searchButtons === 'undefined' || searchButtons.length < 1) {
    return false
  }
  searchButtons.map((button) => {
    return button.addEventListener('click', () => {
      if (!menuOpenState && !searchOpenState) {
        openMenu()
        openSearch()
      } else if (menuOpenState && !searchOpenState) {
        closeNavigation()
        openSearch()
      } else {
        closeMenu()
      }
    }, false)
  })

}

function handleMenuToggle() {
  if (!menuButton || typeof menuButton === 'undefined') {
    return false
  }
  return menuButton.addEventListener('click', () => {
    if (!menuOpenState) {
      openMenu()
      openNavigation()
    } else {
      closeMenu()
    }
  }, false)
}

let prevScrollpos = window.pageYOffset

function hideOnScroll() {
  const currentScrollPos = window.pageYOffset

  if (currentScrollPos >= 100 && prevScrollpos < currentScrollPos) {
    document.querySelectorAll('.SiteNavigation')[0].classList.add('hide')
  } else {
    document.querySelectorAll('.SiteNavigation')[0].classList.remove('hide')
  }

  prevScrollpos = currentScrollPos
}

function siteNavigationHandler() {
  if (!SiteNavigationMenu || typeof SiteNavigationMenu === 'undefined') {
    return false
  } if (!navHolder || typeof navHolder === 'undefined') {
    return false
  } if (!searchHolder || typeof searchHolder === 'undefined') {
    return false
  }

  window.addEventListener('scroll', () => { hideOnScroll() })
  handleMenuToggle()
  handleSearchToggle()
  return true
}
export default siteNavigationHandler


// import { wait } from 'js/utils'
//
// const menuButton = document.getElementById('SiteNavigationMenuButton')
// const searchButton = document.getElementById('SiteNavigationSearchButton')
// const SiteNavigationMenu = document.getElementById('SiteNavigationMenu')
// const SiteNavigation = document.getElementById('SiteNavigation')
// const navHolder = document.getElementById('SiteNavigationMenuHolder')
// const searchHolder = document.getElementById('SiteNavigationMenuSearch')
//
//
// // const SearchButton = document.getElementById('SiteNavigationSearchButton')
// const bodyMenuOpenClass = 'navigation-menu-open'
// const siteNavigationIsClosingClass = 'SiteNavigation--is-closing'
// const siteNavigationMenuOpenClass = 'SiteNavigationMenu--open'
// const menuButtonOpenClass = 'is-active'
// let menuOpenState = false
// let searchOpenState = false
// let navigationOpenState = false
//
// function closeNavigation() {
//   navigationOpenState = false
//   navHolder.classList.remove('block')
//   navHolder.classList.add('hidden')
// }
//
// function openSearch() {
//   searchOpenState = true
//   // eslint-disable-next-line
//   // const SearchFormInput = document.getElementById('SearchFormInput')
//   wait(200)().then(() => document.getElementById('SearchFormInput').focus())
//   searchHolder.classList.add('block')
//   searchHolder.classList.remove('hidden')
//   closeNavigation()
// }
// function closeSearch() {
//   searchOpenState = false
//   searchHolder.classList.remove('block')
//   searchHolder.classList.add('hidden')
// }
// function openNavigation() {
//   navHolder.classList.add('block')
//   navHolder.classList.remove('hidden')
//   navigationOpenState = true
// }
//
// function openMenu() {
//   menuOpenState = true
//   menuButton.classList.add(menuButtonOpenClass)
//   SiteNavigationMenu.classList.add(siteNavigationMenuOpenClass)
//   document.body.classList.add(bodyMenuOpenClass)
// }
//
// function closeMenu() {
//   menuOpenState = false
//   menuButton.classList.remove(menuButtonOpenClass)
//   SiteNavigationMenu.classList.remove(siteNavigationMenuOpenClass)
//   document.body.classList.remove(bodyMenuOpenClass)
//   SiteNavigation.classList.add(siteNavigationIsClosingClass)
//   closeSearch()
//   closeNavigation()
//   wait(2000)().then(() => SiteNavigation.classList.remove(siteNavigationIsClosingClass))
// }
//
//
// function handleSearchToggle() {
//   if (!searchButton || typeof searchButton === 'undefined') {
//     return false
//   }
//   return searchButton.addEventListener('click', () => {
//     if (!menuOpenState && !searchOpenState) {
//       openMenu()
//       openSearch()
//     } else if (menuOpenState && !searchOpenState) {
//       closeNavigation()
//       openSearch()
//     } else {
//       closeMenu()
//     }
//   }, false)
// }
//
// function handleMenuToggle() {
//   if (!menuButton || typeof menuButton === 'undefined') {
//     return false
//   }
//   return menuButton.addEventListener('click', () => {
//     if (!menuOpenState) {
//       openMenu()
//       openNavigation()
//     } else {
//       closeMenu()
//     }
//   }, false)
// }
//
// function siteNavigationHandler() {
//   if (!SiteNavigationMenu || typeof SiteNavigationMenu === 'undefined') {
//     return false
//   } if (!navHolder || typeof navHolder === 'undefined') {
//     return false
//   } if (!searchHolder || typeof searchHolder === 'undefined') {
//     return false
//   }
//
//   handleMenuToggle()
//   handleSearchToggle()
//   return true
// }
// export default siteNavigationHandler

import { playerOrigin } from '.'

class VideoPlayer {
  constructor(host, id, url, parent) {
    this.videoHost = host
    this.videoId = id
    this.videoUrl = url
    this.parent = parent
    this.playerHolderId = 'VideoModalHolder'
    // some stuff we use later on
    this.player = null
    this.videoHolder = null
    this.videoIframeHolder = null
    this.lastVideoId = false
    this.playerHolder = null
    this.openStatus = null
    this.videoDone = false
    //

    const tag = document.createElement('script')
    tag.src = this.videoHost === 'youtube' ? 'https://www.youtube.com/iframe_api' : 'https://player.vimeo.com/api/player.js'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    this.createPlayerHtml()
  }

  onYouTubePlayerAPIReady() {
    // eslint-disable-next-line
    return new YT.Player(this.videoIframeHolder, {
      height: '720',
      width: '1280',
      videoId: this.videoId,
      enablejsapi: true,
      disablekb: true,
      playlist: false,
      controls: true,
      modestbranding: true,
      showinfo: false,
      rel: false,
      origin: playerOrigin,
      events: {
        onReady: this.onPlayerReady
      }
    })
  }

  createPlayerHtml() {
    // Video container
    this.playerHolder = document.createElement('div')
    this.playerHolder.id = this.playerHolderId
    this.playerHolder.classList.add('VideoPlayerModal')

    // video holder
    this.videoHolder = document.createElement('div')
    this.videoHolder.classList.add('VideoPlayerModal__video-holder')
    this.videoIframeHolder = document.createElement('div')
    this.videoIframeHolder.id = 'videoModalIframeHolder'
    this.videoIframeHolder.classList.add('VideoPlayerModal__video-iframe')
    this.videoHolder.appendChild(this.videoIframeHolder)

    // add the video-holder to the parent, and add the parent to the body
    this.playerHolder.appendChild(this.videoHolder)
    this.parent.appendChild(this.playerHolder)
  }

  /* eslint-disable */
  onPlayerReady(event) {
    // Play the video
    event.target.playVideo()
  }

  vimeoPlayer() {
    const options = {
      id: this.videoId,
      width: 1280,
      loop: false
    }
    // eslint-disable-next-line
    return new Vimeo.Player(this.videoIframeHolder, options);
  }

  open() {
    if (!this.player) {
      if (this.videoHost === 'youtube') {
        this.player = this.onYouTubePlayerAPIReady()
      } else if (this.videoHost === 'vimeo') {
        this.player = this.vimeoPlayer()
      }
    }

    if (this.videoHost === 'youtube') {
      if (typeof this.player.playVideo === 'function') {
        // wait(500)().then(() => this.player.playVideo())
        this.player.playVideo()
      }
    } else if (this.videoHost === 'vimeo') {
      //
      if (typeof this.player.play === 'function') {
        this.player.play()
      }
    }

    this.playerHolder.classList.add('VideoPlayerModal--open')
    document.body.classList.add('ArticleVideoPlayerModal--active')
    this.openStatus = true
  }

  close() {
    // remove class to "close" lightbox
    this.playerHolder.classList.remove('VideoPlayerModal--open')
    document.body.classList.remove('ArticleVideoPlayerModal--active')
    this.openStatus = false
    /*
      pause video on close, chek cif vimeo or youtube
    */
    if (this.videoHost === 'youtube') {
      if (typeof this.player.pauseVideo === 'function') {
        this.player.pauseVideo()
      }
    } else if (this.videoHost === 'vimeo') {
      if (typeof this.player.pause === 'function') {
        this.player.pause()
      }
    }
  }
}


export default VideoPlayer

class Loader {
  constructor(parentElem) {
    if (!parentElem || typeof parentElem === 'undefined') {
      return false
    }
    this.parentElem = parentElem
    this.loaderElemId = 'searchLoader'
    this.loaderActiveClass = 'loader--active'
    this.loaderInActiveClass = 'loaderInActiveClassactive'
    this.loaderElem = null

    if (this.loaderElem || typeof this.loaderElem === 'undefined') {
      return false
    }
    this.loaderElem = document.createElement('div')
    this.loaderElem.id = `${this.parentElem.id}__${this.loaderElemId}`
    this.loaderElem.classList.add('Loader')
    const progress = document.createElement('div')
    progress.classList.add('Loader__progress')
    const line = document.createElement('div')
    line.classList.add('Loader__line')
    const sublineInc = document.createElement('div')
    sublineInc.classList.add('Loader__subline', 'Loader__subline-inc')
    const sublineDec = document.createElement('div')
    sublineDec.classList.add('Loader__subline', 'Loader__subline-dec')


    progress.appendChild(line)
    progress.appendChild(sublineInc)
    progress.appendChild(sublineDec)
    this.loaderElem.appendChild(progress)
    this.parentElem.appendChild(this.loaderElem)
  }

  show() {
    if (this.loaderElem || typeof this.loaderElem !== 'undefined') {
      // this.loaderElem.classList.add(this.loaderActiveClass)
      this.loaderElem.style.display = 'block'
    }
  }

  // remove the loader if it exists
  hide() {
    if (this.loaderElem || typeof this.loaderElem !== 'undefined') {
      // this.loaderElem.classList.remove(this.loaderActiveClass)
      // this.loaderElem.classList.add(this.loaderInActiveClass)
      this.loaderElem.style.display = 'none'
    }
  }
}
export default Loader

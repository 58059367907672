import {
  docCookies,
  maxAgeToGMT,
} from '../lib/cookiehelper'

const cookieName = 'approvedCookiePolicy'

const CookieDialog = document.getElementById('CookieDialog')
const CookieAcceptButton = document.getElementById('CookieAcceptButton')

function removeCookieDialog() {
  docCookies.setItem(cookieName, 1, maxAgeToGMT(31536e3))
  CookieDialog.classList.add('hidden')
  setTimeout(() => CookieDialog.remove(), 1000)
}

function handleCookieDialog() {
  if (!CookieDialog || typeof CookieDialog === 'undefined') return false
  if (!CookieAcceptButton || typeof CookieAcceptButton === 'undefined') return false
  if (docCookies.hasItem(cookieName)) return removeCookieDialog()

  CookieDialog.classList.remove('hidden')
  return CookieAcceptButton.addEventListener('click', () => removeCookieDialog(), false)
}

export default handleCookieDialog

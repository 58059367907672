import { wait } from 'js/utils/'
// import * as StackBlur from 'stackblur-canvas';

function loadPlaceholderImage(placeholder) {
  if (placeholder.getAttribute('data-src')) {
    const placeholderImg = new Image()
    placeholderImg.src = placeholder.getAttribute('data-src')
    const width = placeholder.getAttribute('width')
    const height = placeholder.getAttribute('height')
    const ctx = placeholder.getContext('2d')

    placeholderImg.addEventListener('load', () => {
      ctx.drawImage(
        placeholderImg,
        0,
        0,
        width,
        height
      )
    }, false)
  }
}

function loadPlaceholderImages() {
  const placeholders = document.querySelectorAll('canvas[data-src]');
  // Progressively load placeholder images on page load
  [].forEach.call(placeholders, loadPlaceholderImage)
}

export function removePlacehodler(imgElement) {
  if (!imgElement || typeof imgElement === 'undefined') {
    return false
  }
  const img = imgElement
  const parentElement = img.parentNode
  parentElement.classList.add('image-loaded')
  const canvasElement = parentElement.querySelector('canvas')
  if(!canvasElement || typeof canvasElement === 'undefined') {
	  return false
  }

	return wait(200)().then(() => canvasElement.remove())

}
// We want to load the placeholder images as soon as possible.
export default loadPlaceholderImages
